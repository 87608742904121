import { styled, css } from "styled-components";
import theme from "styled-theming";

import { default as NavLink } from "components/Link";
import Watermark from "components/Watermark/";
import {
    BodyM,
    HeadingM,
    LabelM,
    LabelS,
    styleLabelS,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;
`;

export const ImageWrapper = styled.div`
    align-items: center;
    aspect-ratio: 1.5;
    background: var(--block-blue-default);
    display: flex;
    flex-direction: column;
    grid-column: col-start 1 / span 4;
    justify-content: center;
    margin-bottom: 1.75rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    figure {
        inset: 0 0 -1px; /* fix rounding pixels issue */
        position: absolute;

        img {
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
            width: 100%;
        }
    }

    ${MQ.FROM_M} {
        margin-bottom: 2.1875rem;
        ${theme("size", {
            large: css`
                grid-column: col-start 1 / span 12;
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            large: css`
                grid-column: col-start 1 / span 8;
                margin-bottom: 0;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        ${theme("size", {
            medium: css`
                margin-bottom: 2.625rem;
            `,
        })};
    }
`;

export const Content = styled.div`
    ${columnGap};
    display: grid;
    grid-column: col-start 1 / span 4;
    grid-template-areas:
        "heading heading"
        "categories categories"
        "dateandlength length"
        "introduction introduction";
    grid-template-columns: repeat(4, [col-start] 1fr);
    margin-bottom: auto;
    width: 100%;

    ${MQ.FROM_M} {
        ${theme("size", {
            large: css`
                grid-column: col-start 1 / span 12;
                grid-template-areas:
                    "heading heading heading"
                    "categories dateandlength length"
                    "categories introduction introduction";
                grid-template-columns: repeat(12, [col-start] 1fr);
            `,
        })}
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            medium: css`
                grid-template-areas:
                    "heading heading heading"
                    "categories dateandlength length"
                    "introduction introduction introduction";
                grid-template-columns: repeat(6, [col-start] 1fr);
            `,
            large: css`
                grid-column: col-start 9 / span 4;
                grid-template-areas:
                    "heading heading"
                    "categories categories"
                    "dateandlength length"
                    "introduction introduction";
                grid-template-columns: repeat(4, [col-start] 1fr);
            `,
        })};
    }
    ${MQ.FROM_XL} {
        ${theme("size", {
            medium: css`
                grid-template-areas:
                    "heading heading heading"
                    "categories dateandlength length"
                    "categories introduction introduction";
            `,
        })};
    }
`;

export const Heading = styled(HeadingM)`
    grid-area: heading;
    grid-column: col-start 1 / span 4;
    margin-bottom: 0.875rem;
    margin-top: 0;

    span {
        border-bottom: 1px solid var(--color-black-o00);
        transition: border-color 0.2s ease-in-out;
    }

    ${MQ.FROM_M} {
        margin-bottom: 1.3125rem;

        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 4;
            `,
            large: css`
                grid-column: col-start 1 / span 12;
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 6;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        margin-bottom: 1.75rem;
    }
`;

export const Introduction = styled(BodyM)`
    grid-area: introduction;
    grid-column: col-start 1 / span 4;
    margin-bottom: 1.75rem;

    ${MQ.FROM_M} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 4;
            `,
            large: css`
                grid-column: col-start 5 / span 8;
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 6;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 3 / span 4;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }
`;

export const Categories = styled(LabelM)`
    grid-area: categories;
    grid-column: col-start 1 / span 4;

    ${MQ.FROM_M} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 3;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 3;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 2;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }
`;

export const DateAndLength = styled(LabelS)`
    display: flex;
    gap: 0.875rem;
    grid-area: dateandlength;
    grid-column: col-start 1 / span 4;

    ${MQ.FROM_M} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 1 / span 4;
            `,
            large: css`
                grid-column: col-start 5 / span 8;
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 4 / span 3;
            `,
            large: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }

    ${MQ.FROM_XL} {
        ${theme("size", {
            medium: css`
                grid-column: col-start 3 / span 4;
            `,
        })};
    }
`;

export const PublishDate = styled.span`
    ${styleLabelS}
`;

export const Length = styled.span`
    ${styleLabelS}
    svg {
        vertical-align: -0.8em;
    }
`;

export const StyledWatermark = styled(Watermark)`
    color: ${theme("showImage", {
        true: "var(--color-coral)",
        false: "var(--color-coral)",
    })};
    pointer-events: none;
    z-index: 2;
`;

export const Link = styled(NavLink)`
    ${columnGap};
    border-bottom: none;
    color: var(--color-black);
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    width: 100%;

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    &:hover {
        ${Heading} {
            span {
                border-color: var(--text-on-neutral-primary);
            }
        }

        ${ImageWrapper} {
            img {
                ${theme("isSafari", {
                    false: css`
                        transform: scale(1.04);
                    `,
                })};
            }
        }
    }

    ${MQ.FROM_M} {
        ${theme("size", {
            large: css`
                grid-template-columns: repeat(12, [col-start] 1fr);
            `,
        })};
    }
`;
